<template>
    <div>
        <b-card
            bg-variant="light"
            text-variant="black"
            header="Shrnutí objednávky"
            class="text-center"
        >
            <div class="w-100 text-center">
                <h2>
                    Zvolili jste platbu bankovním převodem.
                </h2>
                <br>
                <h2>Platební údaje:</h2>
                <h3>Číslo účtu: {{ transfer.cislo_uctu }}</h3>
                <h3>Variabilní symbol: {{ transfer.variabilni_symbol }}</h3>
                <h3>Částka: {{ transfer.castka }} Kč</h3>
                <br>
                <p>
                    Naskenováním následujícího QR můžete ihned provést úhradu.
                </p>
                <img :src="qr_code_url" />
                <br><br>
                <p>
                    Platební údaje vám byly také zaslány na váš email.
                </p>
                <p>
                    Po přijetí a ověření vaší platby bankovním převodem, bude na vaši emailovou adresu <br> zaslán email se souhrnými informacemi k objednávce.
                </p>
                <br>
                <p>
                    Pokud chcete pokračovat další objednávkou, klikněte zde
                </p>
                <b-button
                    variant="primary"
                    class="mb-2 btn-sm-block"
                    :to="{ name: 'default-view', query: { path: path }}"
                >
                    Další objednávka
                </b-button>
                <br><br>
                <p>
                    Děkujeme za váš nákup.
                    <br>
                    Rancherské speciality z Jestřebic
                </p>
            </div>
        </b-card>
    </div>
</template>

<script>
//import * as constants from '@/store/constants.js'
import { /*state,*/ mutations } from '@/store/index.js'

export default {
    components: { },

    computed: {
        /*platba_checked( ) {
            return state.platba_checked
        },

        platba_error( ) {
            return state.platba_error
        }*/
    },

    data( ) {
        return {
            qr_code_url: null,
            path: null,
            transfer: {
                castka: null,
                cislo_uctu: null,
                variabilni_symbol: null
            }
        }
    },

    methods: {
        readTransfer(platbaData) {
            mutations.readTransfer(platbaData)
                .then((response) => {
                    //console.log("TransferView.readTransfer( )", response)

                    this.transfer = response
                })
        }
    },

    mounted( ) {
        let params = this.$route.query
        
        this.qr_code_url = "/api/transfer/read_qr_code?objednavka_id=" + params.refId
        this.path = params.path

        this.readTransfer({
            objednavka_id: params.refId
        })
    }
}
</script>

<style scoped>
</style>